import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import CTAButton from '../../components/CTAButton';
import LinkWrapper from '../../components/LinkWrapper';
import { AiFillPlayCircle } from 'react-icons/ai';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

/* MODAL SETUP */
const customStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    padding: 0,
    border: 'none',
    borderRadius: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#___gatsby');

function WanaLearn(props) {

  /* QUERY */
  const data = useStaticQuery(graphql`
    query {
      sanityPage(slug: {current: {eq: "/"}}) {
        sections {
          ... on SanityWanaLearn {
            _key
            _type
            sectionTitle
            sectionText
            posts {
              postDescription
              post {
                mainImage {
                  asset {
                    gatsbyImageData(width: 629, height: 460, placeholder: NONE)
                  }
                }
                iframe
                title
                slug {
                  current
                }
              }
            }
            leftCta {
              text
              textColor {
                color
              }
              bgColor {
                color
              }
              ctaRoute {
                route
              }
            }
            rightCta {
              text
              textColor {
                color
              }
              bgColor {
                color
              }
              ctaRoute {
                route
              }
            }
            postsRoute {
              route
            }
          }
        }
      }
    }
  `);

  const sectionData = data.sanityPage.sections.find(item => item?._type === 'wanaLearn');
  
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState(null);

  const openModal = (index) => {
    setVideoURL(sectionData.posts[index].post.iframe);
    setModalIsOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
      {/* WANA LEARN */}
      <Box 
        w="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position='relative' 
        mt={{base:'167px', ms:'167px', md:'11vw', lgs:'11.6vw', xl:'167px'}} 
        bgColor="primaryDarker"
        pb={{base:'100px', ms:'100px', msx:'100px', md:'112px', xl:'112px'}}
        // border="1px" 
      >
        {/* SVG TOP IMAGE */}
        <SvgCurve top />

        {/* CONTENT */}
        <Flex 
          maxW="1286px"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* HEADER */}
          <Heading
            maxW="406px"
            color="white"
            fontFamily="Outfit"
            fontSize={{base:'50px', msx:'65px'}}
            fontWeight="bold"
            lineHeight={{base:'50px', msx:'65px'}}
            letterSpacing={{base:'-0.5px', md:'-0.5px'}}
            // textTransform="capitalize"
            textAlign="center"
            pt={{base:'19vw', msx:'14.58vw', md:'18vw', lg:'16vw', lgs:'14.58vw', xl:'210px'}}
            pb={{base:'3px', md:'3px'}}
            mb={{base:'28px', md:'28px'}}
          >
            {sectionData.sectionTitle}
          </Heading>

          {/* SECTION TEXT */}
          <Text
            w={{base:'87%', md:'87%', mds:'84%', lg:'84%', xl:'870px'}}
            color="white"
            fontFamily="Outfit"
            fontSize={{base:'15px', msx:'20px'}}
            // fontWeight="400"
            lineHeight={{base:'1.5', msx:'35px'}}
            letterSpacing={{base:'0px', msx:'0px'}}
            // textTransform="capitalize"
            textAlign="center"
            pb={{base:'9px', md:'9px'}}
            mb={{base:'4.7vw', msx:'4.7vw', md:'4.7vw', xl:'68px'}}
            // noOfLines={{base:4, md:4, lg:3}}
          >
            {sectionData.sectionText}
          </Text>

          {/* CONTENT CARDS */}
          <Flex
            w={{base:'87%', md:'87%', mds:'84%', lg:'84%', xl:'1286px'}}
            direction={{base:'column', msx:'row'}}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {sectionData.posts.map((post, indx) => {
              return (
                <LearnContent 
                  key={post.post.title} 
                  {...post} 
                  postsRoute={sectionData.postsRoute.route}  
                  openModal={() => {
                    openModal(indx);
                  }} 
                />
              );
            })}
          </Flex>

          {/* CTA´s */}
          <Flex
            w={{base:'83.33vw', ssm:'300px', msx:'350px'}}
            justifyContent="space-between"
            alignItems="center"
            mx="auto"
            p="5px"
          >
            {/* TO LEARN PAGE */}
            <CTAButton
              href={sectionData.leftCta?.ctaRoute?.route}
              fontSize={{base:'11px', msx:'13px'}}
              bgColor={sectionData.leftCta?.bgColor?.color}
              color={sectionData.leftCta?.textColor?.color}
              width={{base:'38.89vw', ssm:'140px', msx:'159px'}}
              height={{base:'48px'}}
              _hover={{bg:'#00A9D3'}}
              ctaText={sectionData.leftCta?.text}
            />

            {/* TO PODCAST PAGE */}
            <CTAButton
              href={sectionData.rightCta?.ctaRoute?.route}
              fontSize={{base:'11px', msx:'13px'}}
              bgColor={sectionData.rightCta?.bgColor?.color}
              color={sectionData.rightCta?.textColor?.color}
              width={{base:'38.89vw', ssm:'140px', msx:'159px'}}
              height={{base:'48px'}}
              _hover={{bg:'#00A9D3', borderColor:'#00A9D3'}}
              ctaText={sectionData.rightCta?.text}
              border={'2px'}
              borderColor={sectionData.leftCta?.bgColor?.color}
            />
          </Flex>
        </Flex>

        {/* SVG BOTTOM IMAGE */}
        <SvgCurve top={false} />
      </Box>

      {/* MODAL */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel='Example Modal'>
        <Box className='player-wrapper'>
          <ReactPlayer className='react-player' width='100%' height='100%' playing={modalIsOpen} auto="true" url={videoURL} controls={true} />
        </Box>
      </Modal>
    </>
  );
}

const SvgCurve = ({ top }) => {
  const position = top ? {top:'-5px'} : {bottom:'-5px'};

  return (
    <Box
      w="100%"
      h={top ? {base:'3%', ms:'3%', msx:'5%', md:'12%', mds:'13%', lg:'14%', lgs:'15%', xl:'16%'} : {base:'5%', msx:'8%', md:'10%'}}
      position="absolute"
      left="0"
      style={{zIndex:0, ...position}}
    >
      {/* TOP SVG */}
      {top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            // d="M0.28,122.88 C250.27,141.63 310.10,-85.34 500.27,106.09 L500.00,0.00 L0.00,0.00 Z"
            d="M-0.27,140.63 C250.27,141.63 310.10,-85.34 500.27,106.09 L500.00,0.00 L0.00,0.00 Z"
          >
          </path>
        </svg> 
      )}

      {/* BOTTOM SVG */}
      {!top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            d="M0.00,49.98 C149.99,150.00 330.98,145.56 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          >
          </path>
        </svg> 
      )}
    </Box>
  );
};

const LearnContent = (props) => {
  const { post, postDescription, postsRoute } = props;

  return (
    <Box
      maxW={{base:'100%', msx:'49%'}}
      mb={{base:'51px', md:'51px', xl:'51px'}}
    >
      {/* POST IMAGE */}
      <Box
        w="100%"
        maxH="460px"
        position="relative"
      >
        <GatsbyImage 
          image={post.mainImage.asset.gatsbyImageData}
          alt={'wana learn content image'}
        />

        {/* PLAY BUTTON IF IS A VIDEO */}
        {post.iframe && (
          <Box
            w="100%"
            h={{base:'100%'}}
            position="absolute"
            top="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {/* ICON */}
            <Box
              w="auto"
              h="auto"
              borderRadius="full"
              cursor='pointer'
              style={{zIndex:2}}
              onClick={props.openModal}
            >
              <AiFillPlayCircle style={{ fontSize: '92px', color: '#272158' }} />
            </Box>

            {/* ICON WHITE BACKGROUND */}
            <Box
              w="46px"
              h="46px"
              bgColor="white"
              borderRadius="full"
              position="absolute"
            />
          </Box>
        )}
      </Box>

      {/* POST TITLE */}
      <Heading
        maxW={{base:'425px', ms:'500px', msx:'327px', md:'363px', mds:'332px', lg:'430px', lgs:'80%', xl:'95%'}}
        mx="auto"
        color="white"
        fontFamily="Outfit"
        fontSize={{base:'33px', msx:'28px', md:'30px', mds:'32px', lg:'34px', lgs:'36px', xl:'38px'}}
        fontWeight="bold"
        lineHeight={{base:'43px', msx:'44px', md:'47px', mds:'51px', lg:'55px', lgs:'59px', xl:'63px'}}
        letterSpacing={{base:'-0.31px', msx:'-0.31px'}}
        // textTransform="capitalize"
        textAlign={{base:'center'}}
        mt={{base:'28px', md:'28px'}}
        mb={{base:'8px', md:'12px', lg:'8px'}}
        noOfLines={{base:3, msx:2, xl:1}}
      >
        {post.title}
      </Heading>

      {/* POST DESCRIPTION */}
      <Text
        maxW={{base:'425px', ms:'500px', msx:'327px', md:'363px', mds:'332px', lg:'430px', lgs:'80%', xl:'515px'}}
        minH={{base:'62px', msx:'96px', md:'96px', mds:'96px', lg:'66px', lgs:'66px'}}
        mx="auto"
        color="white"
        fontSize={{base:'15px', msx:'17px'}}
        fontWeight="400"
        lineHeight={{base:'1.5', msx:'30px'}}
        letterSpacing={{base:'0px', msx:'0px'}}
        // textTransform="capitalize"
        textAlign="center"
        pb={{base:'6px', md:'6px'}}
        mb={{base:'9px', md:'9px'}}
        noOfLines={{base:3, lgs:2}}
      >
        {postDescription}
      </Text>

      {/* LINK TO POST */}
      <Box
        w="max-content"
        h={{base:'28px', msx:'30px'}}
        mx="auto"
      >
        <LinkWrapper href={`${postsRoute}${post.slug.current}`}>
          <Text
            color="white"
            fontFamily="Outfit"
            fontSize={{base:'14px', msx:'16px'}}
            fontWeight="bold"
            lineHeight={{base:'28px', msx:'30px'}}
            letterSpacing={{base:'1px', msx:'1px'}}
            textTransform="uppercase"
            textAlign="center"
            opacity="0.5"
            _hover={{opacity:'0.8'}}
          >
            {'learn more'}
          </Text>
        </LinkWrapper>
      </Box>

    </Box>
  );
};

export default WanaLearn;
