import * as React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import CTAButton from '../../components/CTAButton';
import useWindowSize from '../../utitlities/useWindowSize';

/* IMPORT APP CONTEXT */
import { AppContext } from '../../context';

function WanaBrand(props) {
  const width = useWindowSize();
  /* QUERY */
  const data = useStaticQuery(graphql`
    query {
      sanityPage(slug: {current: {eq: "/"}}) {
        sections {
          ... on SanityWanabrand {
            _type
            cta {
              ctaRoute {
                route
              }
            }
            brandDesktop {
              asset {
                gatsbyImageData
              }
            }
            brandMobile {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const sectionData = data.sanityPage.sections.find(item => item?._type === 'wanabrand');

  const appContext = React.useContext(AppContext);

  return (
    <Flex
      w={{base:'87%', md:'87%', mds:'84%', lg:'84%', xl:'1200px'}} 
      h={{base:'auto'}}
      bgColor="#f57d25"
      mx="auto"
      mt={{base:'38px', md:'38px'}}
      p={{base:'5px 0 0 0', md:'0'}}
      direction={{base:'column', md:'row'}}
      justifyContent="center"
      alignItems="center"
    >
      {/* LEFT CONTENT */}
      <Link
        to={`${sectionData.cta?.ctaRoute?.route}`}
        target="_blank"
      >
        {
          width.width < 768 ? (
            <GatsbyImage 
              image={sectionData.brandMobile.asset.gatsbyImageData}
              alt={'wana subcategory image'}
            />
          ) : (

            <GatsbyImage 
              image={sectionData.brandDesktop.asset.gatsbyImageData}
              alt={'wana subcategory image'}
            />
          )
        }
      </Link>

      {/* MID CONTENT */}
      {/* <Flex
        h="100%"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Heading
          color="White"
          fontFamily="Outfit"
          fontSize={{base:'24px', ms:'26px', msx:'28px', md:'30px', mds:'32px', lg:'34px', lgs:'36px', xl:'38px'}}
          fontWeight={{base:'bold'}}
          lineHeight={{base:'36px', ms:'38px', msx:'40px', md:'42px', mds:'44px', lgm:'65px'}}
          letterSpacing={{base:'-0.29px', md:'-0.29px'}}
          textAlign="center"
          // textTransform="capitalize"
          mb={{base:'10px', md:'10px'}}
        >
          {sectionData.header}
        </Heading>

        <Text
          maxW="92%"
          color="white"
          fontSize={{base:'15px', md:'19px', mds:'21px', lg:'23px', lgs:'25px', xl:'27px'}}
          fontWeight={{base:'500'}}
          lineHeight={{base:'1.2', md:'1.2'}}
          letterSpacing={{base:'-0.21px', md:'-0.21px'}}
          textAlign="center"
          // textTransform="capitalize"
          mb={{base:'20px', md:'0px'}}
        >
          {sectionData.description}
        </Text>
      </Flex> */}

      {/* RIGHT CONTENT */}
      {/* <Flex
        h="100%"
        justifyContent="center"
        alignItems="center"
      >
        <CTAButton
          // href={sectionData.cta?.ctaRoute?.route}
          onClick={appContext.actions.shopCBDModalControl}
          fontSize={{base:'13px'}}
          bgColor={sectionData.cta?.bgColor?.color}
          color={sectionData.cta?.textColor?.color}
          width={{base:'141px', md:'132px', mds:'141px'}}
          height={{base:'48px'}}
          _hover={{bg:'#01A7CF'}}
          ctaText={sectionData.cta?.text}
        />
      </Flex> */}

    </Flex>
  );
}

export default WanaBrand;
