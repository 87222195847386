import * as React from 'react';
import { Box, Text, Link, Image, Flex } from '@chakra-ui/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import CTAButton from '../../components/CTAButton';

function ComboFindWanaHome(props) {
  const { header, description, cta, mainImage, parallaxImgs } = props;
  
  const mainImg = getImage(mainImage.asset);

  return (
    <Box
      w="100%"
      h="auto"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      // bgColor="greenMantis"
      mt={{base:'121px', md:'12.71vw', xl:'183px'}}
    >
      <ParallaxProvider>
        <Flex
          maxW={{base:'83%', lg:'84%', xl:'1200px'}}
          justifyContent='center'
          alignItems="center"
          flexDirection={{base:'column', md:'row'}}
          position="relative"
          mx="auto"
          // border="1px"
        >
          <Flex
            direction="column"
            alignItems={{base:'center', md:'flex-start'}}
            pr={{md:'49px', mds:'54px', lg:'60px', lgs:'67px', xl:'74px'}}
          >
            {/* HEADER */}
            <Text
              maxW={{base:'100%', md:'95%', mds:'306px', lgs:'340px', xl:'340px'}}
              as='h2'
              color={header.textColor?.color || 'primary'}
              fontSize={{base:'40px', md:'65px'}}
              fontWeight='800'
              lineHeight={{base:'45px', md:'65px'}}
              letterSpacing={{base:'-0.5px'}}
              textAlign={{base:'center', md:'left'}}
              // textTransform='capitalize'
              pb={{base:'22px', xl:'12px'}}
              // border="1px"
            >
              {header.headerText}
            </Text>

            {/* DESCRIPTION */}
            <Text 
              as='p' 
              fontSize={{base:'15px', md:'17px'}}
              lineHeight={{base:'25px', md:'30px'}}
              letterSpacing={{base:'0'}}
              color='primary' 
              textAlign={{base:'center', md:'left'}}
              pb={{base:'34px', md:'33px'}}
            >
              {description}
            </Text>

            {/* BUTTON */}
            <Box
              pb={{base:'70px'}}
            >
              <CTAButton
                href={cta.ctaRoute.route || '/'}
                fontSize={{base:'13px'}}
                bgColor={cta.bgColor?.color || 'blue'}
                color={cta.textColor?.color|| 'white'}
                width={{base:'125px', md:'125px'}}
                height={{base:'48px'}}
                _hover={{bg:'#312669'}}
                ctaText={cta.text}
              />
            </Box>
          </Flex>

          {/* IMAGE */}
          <Box 
            w={{base:'298px', ms:'373px', md:'41vw', mds:'45vw', lg:'45.41vw', lgs:'42vw', xl:'574px'}}
            h={{base:'289px', ms:'361px', md:'41vw', mds:'42vw', lg:'44.23vw', lgs:'41vw', xl:'559px'}}
            zIndex="2"
          >
            <GatsbyImage image={mainImg} alt={mainImage.asset.altText || 'happy wana user'} />
          </Box>

          {/* LEFT PARALLAX IMAGE */}
          <Flex
            maxW={{base:'212px', ms:'265px', mds:'252px', lg:'252px', lgs:'252px', xl:'252px'}}
            maxH={{base:'212px', ms:'265px', mds:'252px', lg:'252px', lgs:'252px', xl:'252px'}}
            position="absolute"
            top={{base:'0', md:'10px', mds:'25px', lg:'85px', lgs:'100px', xl:'130px'}}
            left={{base:'-45%', ms:'-45%', md:'-23vw', mds:'-20vw', lg:'-20vw', lgs:'-21.3vw', xl:'-323px'}}
          >
            <Parallax styleInner={{transition: `${parallaxImgs[0]?.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[30, -20]} tagOuter='figure'>
              <Image 
                src={parallaxImgs[0]?.image.asset.url}
                boxSize={{base:'212px', ms:'265px', mds:'252px', lg:'252px', lgs:'252px', xl:'252px'}}
                objectFit="contain"
              />
            </Parallax>
          </Flex>

          {/* RIGHT PARALLAX IMAGE */}
          <Flex
            maxW={{base:'276px', ms:'345px', md:'40vw', mds:'44vw', lg:'44.41vw', lgs:'41vw', xl:'444px'}}
            maxH={{base:'276px', ms:'345px', md:'40vw', mds:'41vw', lg:'43.23vw', lgs:'40vw', xl:'444px'}}
            position="absolute"
            bottom={{base:'-5%', md:'45px', mds:'65px', lg:'70px', lgs:'80px', xl:'170px'}}
            right={{base:'-25%', ms:'-25%', md:'-55px', mds:'-60px', lg:'-75px', lgs:'-80px', xl:'-80px'}}
            zIndex="1"
          >
            <Parallax styleInner={{transition: `${parallaxImgs[1]?.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[30, -20]} tagOuter='figure'>
              <Image 
                src={parallaxImgs[1]?.image.asset.url}
                boxSize={{base:'276px', ms:'345px', md:'40vw', mds:'44vw', lg:'44.41vw', lgs:'41vw', xl:'444px'}}
                objectFit="contain"
              />
            </Parallax>
          </Flex>

          {/* BOTTOM PARALLAX IMAGE */}
          <Flex
            maxW={{base:'143px', ms:'179px', md:'22vw', mds:'24vw', lg:'23vw', lgs:'21vw', xl:'284px'}}
            maxH={{base:'146px', ms:'183px', md:'22.2vw', mds:'24.2vw', lg:'23.2vw', lgs:'21.2vw', xl:'290px'}}
            position="absolute"
            bottom={{base:'-12%', ms:'-12%', md:'0', mds:'-54px', lg:'-54px', lgs:'-54px', xl:'-54px'}}
            left={{base:'0', md:'280px', mds:'240px', lg:'220px', lgs:'270px', xl:'270px'}}
            zIndex="2"
            // border="1px"
          >
            <Parallax styleInner={{transition: `${parallaxImgs[2]?.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[-7, 32]}>
              <Image 
                src={parallaxImgs[2]?.image.asset.url}
                boxSize={{base:'143px', ms:'179px', md:'22vw', mds:'24vw', lg:'23vw', lgs:'21vw', xl:'284px'}}
                objectFit="contain"
              />
            </Parallax>
          </Flex>

        </Flex>
      </ParallaxProvider>
      
    </Box>
  );
}

export default ComboFindWanaHome;
