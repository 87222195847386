
const getImageSizes = (width = 0, desktopImg = false, tabletImg = false, mobileImg = false) => {
  const sizes = {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  }
  if (mobileImg && 544 > width) {
    sizes.isDesktop = false;
    sizes.isTablet = false;
    sizes.isMobile = true;
  } else if (tabletImg && 768 > width) {
    sizes.isDesktop = false;
    sizes.isTablet = true;
    sizes.isMobile = false;
  } else if (desktopImg) {
    sizes.isDesktop = true;
    sizes.isTablet = false;
    sizes.isMobile = false;
  }
  
  return sizes;
};

module.exports = getImageSizes;
