import * as React from 'react';
import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { AiFillPlayCircle } from 'react-icons/ai';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import CTAButton from '../../components/CTAButton';
import OneColumnText from '../BlogPostSections/OneColumnText';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

/* MODAL SETUP */
const customStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    padding: 0,
    border: 'none',
    borderRadius: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#___gatsby');

/* RICH TEST STYLES */
const oneColumnTextStyles = {
  fontSizes: {
    'normal': {base:'17px', md:'17px', lg:'20px'},
    'h4': {base:'22px', md:'19px', lg:'22px'},
    'h3': {base:'24px', md:'21px', lg:'24px'},
    'h2': {base:'26px', md:'23px', lg:'26px'},
    'h1': {base:'28px', md:'25px', lg:'28px'},
  },
  fontWeights: {
    'normal': '400',
    'strong': '600',
  },
  lineHeights: {
    'normal': {base:'1.5', md:'31px'},
    'h4': {base:'1.5', md:'33px'},
    'h3': {base:'1.5', md:'35px'},
    'h2': {base:'1.5', md:'37px'},
    'h1': {base:'1.5', md:'39px'},
  },
  letterSpacings: {
    'normal': {base:'0px', md:'0px'},
    'h4': {base:'0px', md:'0px'},
    'h3': {base:'0px', md:'0px'},
    'h2': {base:'0px', md:'0px'},
    'h1': {base:'0px', md:'opx'},
  },
  textAlign: {base:'left', md:'left'},
  mbSection: {base:'0px', md:'0px'},
  mbContainers: {base:'20px', md:'20px'},
  hover: {borderColor:'primary'},
  linkFontStyle: 'italic',
  listsLeftMargin: {base:'30px', md:'60px'},
  style:{wordSpacing:'2px'}
};

function TheWanaWay(props) {
  /* QUERY */
  const data = useStaticQuery(graphql`
    query {
      sanityPage(slug: {current: {eq: "/"}}) {
        sections {
          ... on SanityTheWanaWay {
            _key
            _type
            sectionTitle
            _rawSectionText
            cta {
              text
              ctaRoute {
                route
              }
              textColor {
                color
              }
              bgColor {
                color
              }
            }
            coverImage {
              asset {
                gatsbyImageData(width: 575, height: 357, placeholder: NONE)
              }
            }
            videoURL
            parallaxImg {
              image {
                asset {
                  url
                }
              }
              velocity
            }
            leftParallaxImg {
              image {
                asset {
                  url
                }
              }
              velocity
            }
          }
        }
      }
    }
  `);

  const sectionData = data.sanityPage.sections.find(item => item?._type === 'theWanaWay');

  /* MODAL CONTROL */
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState(null);

  const openModal = () => {
    setVideoURL(sectionData.videoURL);
    setModalIsOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
      {/* VIDEO SECTION */}
      <Box
        w="100%"
        h="auto"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        mt={{
          base:'-16vw', 
          ms:'-21vw', 
          msx:'-23vw', 
          md:'-1.5vw', 
          mds:'-2vw', 
          lg:'-2vw',
          lgs:'-2vw', 
          xl:'-2vw', 
          xxl:'-10vw', 
          sl:'-10vw', 
          ssl:'-10vw', 
          xsl:'-10vw', 
          msl:'-10vw', 
          ml:'-10vw',
        }} 
        fontFamily="Outfit"
      >
        {/* CONTENT */}
        <ParallaxProvider>
          <Flex
            w={{base:'87%', md:'87%', mds:'84%', lg:'84%', xl:'1200px'}} 
            mx='auto' 
            direction={{base:'column', md:'row'}}
          >
            {/* TEXT */}
            <Box
              w={{base:'100%', md:'49%'}}
              mr={{base:'0', md:'3%'}}
              position="relative"
            >
              <Heading
                color="primary"
                fontFamily="Outfit"
                fontSize={{base:'45px', md:'45px', mds:'50px', lg:'60px', lgs:'65px'}}
                fontWeight="bold"
                lineHeight={{base:'45px', md:'45px', mds:'50px', lg:'60px', lgs:'65px'}}
                letterSpacing={{base:'-0.5px', md:'-0.5px'}}
                // textTransform="capitalize"
                pb={{base:'10px', md:'17px'}}
                mb={{base:'10px', md:'10px'}}
                textAlign={{base:'center', md:'left'}}
              >
                {sectionData.sectionTitle}
              </Heading>

              <Box
                pl={{base:'0', md:'25px', mds:'30px', lg:'40px'}}
              >
                {sectionData._rawSectionText && (
                  <OneColumnText 
                    _rawOneColumnText={sectionData._rawSectionText} 
                    styles={oneColumnTextStyles}
                  />
                )}

                <Flex 
                  justifyContent={{base:'center', md:'flex-start'}}
                  mb={{base:'20px', md:'0'}}
                >
                  {sectionData.cta && (
                    <CTAButton
                      href={sectionData.cta?.ctaRoute?.route}
                      fontSize={{base:'13px'}}
                      bgColor={sectionData.cta?.bgColor?.color}
                      color={sectionData.cta?.textColor?.color}
                      width={{base:'160px'}}
                      height={{base:'48px'}}
                      _hover={{bg:'#272158'}}
                      ctaText={sectionData.cta?.text}
                    />
                  )}
                </Flex>
              </Box>

              {/* LEFT PARALLAX IMAGE */}
              <Flex
                maxW={{base:'175px', md:'160x'}}
                maxH={{base:'210px'}}
                position="absolute"
                bottom={{base:'-70%', ssm:'-90%', ms:'-110%', msx:'-55vw', md:'-8vw', mds:'-7.5vw', lg:'-7.5vw', lgs:'-6vw', xl:'-40px'}}
                left={{base:'-15vw', ms:'-10vw', msx:'-11.5vw', md:'-14.5vw', mds:'-12.5vw', lg:'-12.5vw', lgs:'-12.5vw', xl:'-180px'}}
                zIndex="2"
              >
                <Parallax styleInner={{transition: `${sectionData.leftParallaxImg?.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[30, -20]} tagOuter='figure'>
                  <Image 
                    src={sectionData.leftParallaxImg?.image.asset.url}
                    boxSize={{base:'130px', msx:'140px', md:'140px', mds:'145px', lg:'150px', lgs:'155px', xl:'160px'}}
                    objectFit="contain"
                  />
                </Parallax>
              </Flex>
            </Box>

            {/* VIDEO */}
            <Flex
              w={{base:'100%', md:'48%'}}
              h={{base:'300px', msx:'357px'}}
              position="relative"
              className="wana-way-image"
              justifyContent="center"
              alignItems="center"
            >
              <GatsbyImage 
                image={sectionData.coverImage.asset.gatsbyImageData}
                alt={'wana subcategory image'}
              />

              {/* PLAY BUTTON IF MEDIA IS A VIDEO */}
              {sectionData.videoURL && (
                <Box
                  w="100%"
                  h={{base:'100%'}}
                  position="absolute"
                  top="0"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* ICON */}
                  <Box
                    w="auto"
                    h="auto"
                    borderRadius="full"
                    cursor='pointer'
                    style={{zIndex:2}}
                    onClick={openModal}
                  >
                    <AiFillPlayCircle style={{ fontSize: '92px', color: '#272158' }} />
                  </Box>

                  {/* ICON WHITE BACKGROUND */}
                  <Box
                    w="46px"
                    h="46px"
                    bgColor="white"
                    borderRadius="full"
                    position="absolute"
                  />
                </Box>
              )}

              {/* RIGHT PARALLAX IMAGE */}
              <Flex
                maxW={{base:'120px', ms:'140px', msx:'155px', md:'165px'}}
                maxH={{base:'162px'}}
                position="absolute"
                top={{base:'-35px', ms:'-65px', msx:'-70px', md:'-50px'}}
                right={{base:'-25px', ms:'-25px', msx:'-25px', md:'-45px', mds:'-45px', lg:'-45px', lgs:'-45px', xl:'-45px'}}
                zIndex="2"
              >
                <Parallax styleInner={{transition: `${sectionData.parallaxImg.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[30, -20]} tagOuter='figure'>
                  <Image 
                    src={sectionData.parallaxImg.image.asset.url}
                    boxSize={{base:'120px', ms:'140px', msx:'155px', md:'165px'}}
                    // objectFit={{base:'cover', xxl:'contain'}}
                    // objectPosition={{base:'5% 5%', xxl:'initial'}}
                  />
                </Parallax>
              </Flex>

            </Flex>

          </Flex>

        </ParallaxProvider>
      </Box>

      {/* MODAL */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel='Example Modal'>
        <Box className='player-wrapper'>
          <ReactPlayer className='react-player' width='100%' height='100%' playing={modalIsOpen} auto url={videoURL} controls={true} />
        </Box>
      </Modal>
    </>
  );
}

export default TheWanaWay;
