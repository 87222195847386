const filterTypes = {
  ALL: 'all',
  SUBCATEGORY: 'subcategory',
  CATEGORY: 'category',
  EFFECT: 'effect',
  LINEAGE: 'classAndRatios',
  STORETYPE: 'storeType',
  STATE: 'state',
};

export default filterTypes;
