import * as React from 'react';
import { Box } from '@chakra-ui/react';

function TextPathAnimation({ text }) {
  const displayText = text.reduce((a, c) => (a === '' ? c : `${a}. ${c}`), '');

  return (
    <Box
      w="100%"
      overflow="hidden"
      fontSize={{md:'16px', lg:'16px', xl:'16px'}}
      fontWeight="700"
      textTransform="uppercase"
      style={{
        fontFamily: 'Nunito Sans,sans-serif',
        userSelect: 'none',
        // transform: 'scale(2)',
      }}
      dangerouslySetInnerHTML={{
        __html: `
        <svg width="100%" height="100%" viewBox="0 0 500 79">
          <path id="curve" fill="transparent" d="M -0.27 42.27 C 149.83 9.7 326.46 98.52 500.27 38 L 500 80 L 0 80 L -0.27 42.27 C 149.83 9.7 326.46 98.52 500.27 38 Z"></path>
          <text fill="#DDDBE8">
              <textPath xlink:href="#curve">
                      ${displayText} &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp ${displayText} 
                   <animate id="anim1" attributeName="startOffset"
                     from="500" to="0"
                     begin="0s;anim2.end" dur="10s"
                     repeatCount="indefinite" 
                   />
      
               </textPath>
          </text>
        </svg>
        `,
      }}
    ></Box>
  );
}

export default TextPathAnimation;
